@import 'normalize.css';
@import '~@blueprintjs/core';
@import '~@blueprintjs/popover2';

:focus {
    outline: none !important;
}

a,
a:active,
a:hover,
a:focus {
    outline: none;
}

body {
    line-height: 1.5 !important;
}

.bp4-button {
    font-family: 'Whitney A', 'Whitney B', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
        'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 700;

    &.bp4-small {
        font-size: 12px;
    }
}

.bp4-alert {
    &-contents {
        flex-grow: 1;
    }

    &-footer {
        margin-top: 20px;
    }
}

.bp4-intent-slate {
    color: #5c7097 !important;

    &.bp4-disabled {
        color: rgba(92, 112, 151, 0.65) !important;
    }
}

.bp4-input {
    border-radius: 0 !important;
    box-shadow: inset 0 0 0 2px rgba(16, 22, 26, 0.15);

    > .fal,
    > .far,
    > .fas,
    > .fad,
    > .fab {
        margin: 9px 9px 0 3px;
        width: 14px;
        text-align: center;
    }

    &.bp4-large {
        > .fal,
        > .far,
        > .fas,
        > .fad,
        > .fab {
            font-size: 16px;
            margin: 11px;
        }
    }

    &-group {
        > .fal,
        > .far,
        > .fas,
        > .fad,
        > .fab {
            position: absolute;
            top: 0;
            left: 0;
            margin: 9px;
            z-index: 1;
            width: 14px;
            text-align: center;
        }

        &.bp4-large {
            > .fal,
            > .far,
            > .fas,
            > .fad,
            > .fab {
                font-size: 16px;
                margin: 11px;
            }
        }
    }
}
